import React, { Component } from "react";
import { get, getDatabase, ref, child, query, orderByChild } from 'firebase/database';

import UserRow from './UserRow';
import { createRoot } from "react-dom/client";

class Betas extends Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();
    }
    render() {
        return (
            <div className="full-width mdc-data-table">
                <div className="mdc-data-table__table-container">
                    <table className="mdc-data-table__table" aria-label="Dessert calories">
                        <thead>
                            <tr className="mdc-data-table__header-row">
                                <th className="mdc-data-table__header-cell" role="columnheader" scope="col">Nom d'utilisateur</th>
                                <th className="mdc-data-table__header-cell" role="columnheader" scope="col">Adresse email</th>
                                <th className="mdc-data-table__header-cell" role="columnheader" scope="col">UID</th>
                                <th className="mdc-data-table__header-cell" role="columnheader" scope="col">Rôle(s)</th>
                            </tr>
                        </thead>
                        <tbody className="mdc-data-table__content" ref={this.contentRef}>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const dbref = ref(getDatabase())
        const users = query(child(dbref, 'users'), orderByChild('username'));
        get(users).then((snapshot) => {
            const elements = [];
            snapshot.forEach((child) => {
                if (snapshot.child('beta').val()) {
                    const userrow = <UserRow name={child.child('username').val()} email={child.child('email').val()} uid={child.key} beta={child.child('beta').val()} admin={child.child('admin').val()} />
                    elements.push(userrow);
                }
            });
            const root = createRoot(this.contentRef.current);
            root.render(elements);
        });
    }
}

export default Betas;