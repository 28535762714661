import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import '../App.scss';
import logo from '../assets/img/common/favicon_v2.svg';

class AppBase extends Component {
    constructor(props) {
        super(props);

        this.logoutPressed = this.logoutPressed.bind();
    }

    render() {
        return (
            <div>
                <header className="top-bar mdc-top-app-bar">
                    <div className="mdc-top-app-bar__row">
                        <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
                            <img src={logo} height="48" />
                            <span className="mdc-top-app-bar__title">InfoGare Admin</span>
                        </section>
                        <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
                            <Link to="/users"><button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Users">people</button></Link>
                            <Link to="/beta_users"><button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Beta testers">bug_report</button></Link>
                            <Link to="/search"><button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Search">search</button></Link>
                            <button className="material-icons mdc-top-app-bar__action-item mdc-icon-button" aria-label="Logout" onClick={this.logoutPressed}>logout</button>
                        </section>
                    </div>
                </header>
                <main className="mdc-top-app-bar--fixed-adjust">
                    <Outlet />
                </main>
            </div>
        );
    }
    
    logoutPressed() {
        getAuth().signOut().then(() => {
            window.location.href = "/";
        });
    }
}

export default AppBase;