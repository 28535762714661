import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { Component } from 'react';

class Home extends Component {
    constructor(props) {
        super(props);

        this.userNameRef = React.createRef();
    }
    render() {
        return (
            <div>
                <h1>Bienvenue sur InfoGare Admin !</h1>
                <b>Connecté(e) en tant que <span ref={this.userNameRef}></span></b>
            </div>
        )
    }

    componentDidMount() {
        onAuthStateChanged(getAuth(), (user) => {
            this.userNameRef.current.innerText = user.displayName;
        });
    }
}

export default Home;