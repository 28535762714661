import React, { Component } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import AppBase from './AppBase';
import Home from './Home';

import '../App.scss';
import Users from './Users';
import Betas from './Betas';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AppBase />} >
                        <Route index element={<Home />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/beta_users" element={<Betas />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;