import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { initializeApp } from 'firebase/app';
import { signInWithCustomToken, getAuth, onAuthStateChanged } from 'firebase/auth';

initializeApp({
	apiKey: "AIzaSyCWi0EChm97lofJrhqBp6wRRtgQGKq8IEg",
	authDomain: "infogares-f.firebaseapp.com",
	databaseURL: "https://infogares-f-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "infogares-f",
	storageBucket: "infogares-f.appspot.com",
	messagingSenderId: "984551924335",
	appId: "1:984551924335:web:4c5d88f3e7333180eb639a",
	measurementId: "G-LCE3P91J1N"
});


onAuthStateChanged(getAuth(), (user) => {
	if (user) {
		const root = ReactDOM.createRoot(document.getElementById('root'));
		root.render(
			<App />
		);
	} else if (document.location.search) {
		const params = new URLSearchParams(document.location.search);
		if (params.has('token')) {
			signInWithCustomToken(getAuth(), params.get('token')).then(() => {
				document.location.href = document.location.pathname;
			}).catch(error => {
				console.error(error);
			});
		}
	} else {
		window.location.href='https://auth.infogare.fr/v2/redirect?service=infogare&version=admin';
	}
});


