import React, { Component } from "react";

class UserRow extends Component {
    constructor(props) {
        super(props);

        this.chipsRef = React.createRef();
    }
    render() {
        return (
            <tr className="mdc-data-table__row">
                <th className="mdc-data-table__cell" scope="row">{this.props.name}</th>
                <td className="mdc-data-table__cell mdc-data-table__cell--numeric">{this.props.email}</td>
                <td className="mdc-data-table__cell mdc-data-table__cell--numeric">{this.props.uid}</td>
                <td className="mdc-data-table__cell" ref={this.chipsRef}>
                    <span className="mdc-evolution-chip" role="row" id="c0">
                        <span className="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell">
                            <button className="mdc-evolution-chip__action mdc-evolution-chip__action--primary" type="button" tabindex="0">
                            <span className="mdc-evolution-chip__ripple mdc-evolution-chip__ripple--primary"></span>
                            <span className="mdc-evolution-chip__text-label">Utilisateur</span>
                            </button>
                        </span>
                    </span>
                    {this.props.beta &&
                        <span className="mdc-evolution-chip" role="row" id="c1">
                            <span className="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell">
                                <button className="mdc-evolution-chip__action mdc-evolution-chip__action--primary" type="button" tabindex="0">
                                <span className="mdc-evolution-chip__ripple mdc-evolution-chip__ripple--primary"></span>
                                <span className="mdc-evolution-chip__text-label">Béta testeur</span>
                                </button>
                            </span>
                        </span>
                    }
                    {this.props.admin &&
                        <span className="mdc-evolution-chip" role="row" id="c2">
                            <span className="mdc-evolution-chip__cell mdc-evolution-chip__cell--primary" role="gridcell">
                                <button className="mdc-evolution-chip__action mdc-evolution-chip__action--primary" type="button" tabindex="0">
                                <span className="mdc-evolution-chip__ripple mdc-evolution-chip__ripple--primary"></span>
                                <span className="mdc-evolution-chip__text-label">Administrateur</span>
                                </button>
                            </span>
                        </span>
                }
                </td>
            </tr>
        );
    }
}

export default UserRow;